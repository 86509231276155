<template>
      <div class="school-data-wrap">
    <div>
      <div
        class="school-info"
        v-for="(item, index) in schoolFindData.data.list"
        :key="index"
      >
        <div class="info-logo">
          <router-link
            tag="a"
            target="_blank"
            :to="{
                path: '/schoolDetail',
                query: { gbdm: item.gbdm, zyz: item.zyz,pc:item.pc,yxmc:item.yxmc,yxxh:item.yxxh }
            }"
          >
            <img :src="'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'" :alt="item.yxmc" width="100%" height="100%" />
          </router-link>
        </div>
        <div class="info-introduce">
          <div class="school-name">
            <!-- target="_blank" -->
            <router-link
              tag="a"
              target="_blank"
              :to="{
                path: '/schoolDetail',
                query: { gbdm: item.gbdm, zyz: item.zyz,pc:item.pc,yxmc:item.yxmc,yxxh:item.yxxh }
              }"
            >
              <h3>{{ item.yxmc }}
                <img src="../assets/images/ico2.png" alt="985" title="985" v-if="item.sf985&&item.sf985===1">
                <img src="../assets/images/ico1.png" alt="211" title="211" v-if="item.sf211&&item.sf211===1">
                <img src="../assets/images/ico8.png" alt="双一流" title="双一流" v-if="item.sfsyl&&item.sfsyl===1">
                <img src="../assets/images/ico9.png" alt="101" title="101" v-if="item.sf101&&item.sf101===1">
                <img src="../assets/images/ico3.png" alt="研究生院" title="研究生院" v-if="item.yfyjsy&&item.yfyjsy===1">
                <!-- <span v-if="$route.path === '/schoolRecommend'">[{{ item.zyz }}]专业组</span> -->
               </h3>
            </router-link>
            <!-- <div class="school-lqgl" v-if="$route.path === '/schoolRecommend'">
              (录取率:{{ item.lqgl }}%)
            </div> -->
          </div>
          <div class="school-category">
            <div class="category-wrap">
              <!-- <div class="category-item top-positon">
                往年批次:
                <span>{{ item.pc === ''?'-': item.pc}}</span>
              </div>
              <div class="category-item">
                院校属性:
                <span v-if="item.sf985 !== '0'">985&nbsp;</span>
                <span v-if="item.sf211 !== '0'">211&nbsp;</span>
                <span v-else>普通高校</span>
              </div>
              <div class="category-item">所在地区:{{ item.yxdq }}</div>
              <div class="category-item">
                <button
                  type="button"
                  class="deleteSchool"
                  v-if="item.collect"
                >
                  取消收藏
                </button>
                <button
                  type="button"
                  class="addSchool"
                  v-else
                >
                  加入收藏
                </button>
              </div> -->
              <div class="category-item" style="width:320px;margin-top: 10px;font-size:16px;">
                <span style="font-size: 16px;color: #ee5e45;">院校相关信息：</span>
                <span v-if="item.pc&&item.pc===6"><span style="color:blue">往年</span>本科批</span>
                <span v-if="item.pc&&item.pc===7"><span style="color:blue">往年</span>专科批</span>
              </div>
              <div class="category-item">院校代号：{{item.sb===''||item.sb===null?'':item.sb}}</div>
              <div class="category-item">一流学科：{{ item.zdxk===''||item.zdxk===null?'':item.zdxk }}</div>
              <div class="category-item">隶属：{{item.ls===''||item.ls===null?'':item.ls}}</div>
              <div class="category-item">硕士点：{{item.ssdgs===''||item.ssdgs===null?'':item.ssdgs}}</div>
              <div class="category-item">院校类型：{{item.xxlbmc===''||item.xxlbmc===null?'':item.xxlbmc}}</div>
              <div class="category-item">博士点：{{item.bsdgs===''||item.bsdgs===null?'':item.bsdgs}}</div>
              <div class="category-item">
                <div>
                <a :comp_id="item.yxxh" :comp_yxmc="item.yxmc" :comp_logo="'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'" :comp_pc="item.pc"
                href="javascript:;" @click="yxdbClick($event,item,index,1)" class="btnBt" v-if="currentArr.indexOf(index)<0"
                 style="margin-top:2px !important;border: 1px solid #09F;">
                加入院校对比</a>
                <a :comp_id="item.yxxh" :comp_yxmc="item.yxmc" :comp_logo="'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'" :comp_pc="item.pc"
                href="javascript:;" @click="yxdbClick($event,item,index,2)" class="btnBt" v-if="currentArr.indexOf(index)>=0"
                 style="margin-top:2px !important;border: 1px solid #09F;" :class="{'current':(currentArr.indexOf(index)>=0)}">
                取消院校对比</a>
                </div>
              </div>
            </div>
            <div class="info-data-wrap">
              <table class="info-data" style="margin-top: 51px;">
                <tr>
                  <th rowspan="2">年度</th>
                  <th colspan="6">录取分</th>
                  <el-tooltip placement="top">
                  <div slot="content" style="width:400px;">
                    等效分就是今年的分数相当于往年的多少分数，它使得不同年分的分数能直接进行对比分析。
                    比如说2015理科100名是628分，等效于2014年理科100名的623分、2013年理科100名的588分，就是说按照试题难度系数、考生分数分布情况等，如果2015理科100名是628分的成绩，那放在2014年的高考中相当于考了623分，要是在2013年比较难的情况下，那相当于是考了588分。
                    等效分是基于考生成绩分布情况，系统通过科学的数学模型自动换算得来的。
                    具体到使用时，比如某学校14年在湖南的投档线是623分，入档位次是100名，那么15年就需要628分才能入档，由于一分段表在一个分数上有多个考生，因此，在使用等效分时，最好结合省排名来综合判断，使两个数据的偏差得到有效的纠正，因为在同分比较多的情况下，1分之差可能相差百名之外。
                  </div>
                <th colspan="3">等效分</th>
                </el-tooltip>
                  <th colspan="2">计划人数</th>
                </tr>
                <tr>
                  <th>最低分</th>
                  <th>平均分</th>
                  <th>最高分</th>
                  <th>投档线</th>
                  <el-tooltip placement="top">
                    <div slot="content">
                      是院校当年最低录取分数与该院校在该省所在招生批次录取控制分数线的差值
                    </div>
                    <th>线差</th>
                  </el-tooltip>
                  <el-tooltip placement="top">
                    <div slot="content">我的省排名<span v-text="userInfo.spm"></span></div>
                    <th>入档位次</th>
                  </el-tooltip>
                  <th>最低分</th>
                  <th>平均分</th>
                  <th>最高分</th>
                  <th>往年</th>
                  <th>今年</th>
                </tr>
                <tr v-for="(innerItem, index) in item.subs" :key="index">
                  <td>{{ innerItem.nd===0?'平均':innerItem.nd }}</td>
                  <td>{{innerItem.zdf===''||innerItem.zdf===null?'-':innerItem.zdf}}</td>
                  <td>{{innerItem.pjf===''||innerItem.pjf===null?'-':innerItem.pjf}}</td>
                  <td>{{innerItem.zgf===''||innerItem.zgf===null?'-':innerItem.zgf}}</td>
                  <td>{{innerItem.tdx===''||innerItem.tdx===null?'-':innerItem.tdx}}</td>
                  <td>{{innerItem.xc===''||innerItem.xc===null?'-':innerItem.xc}}</td>
                  <td>{{innerItem.rdwc===''||innerItem.rdwc===null?'-':innerItem.rdwc}}</td>
                  <td v-if="innerItem.nd===0" class="yuceTdx">{{innerItem.zdtwf===''||innerItem.zdtwf===null?'-':innerItem.zdtwf}}</td>
                  <td v-if="innerItem.nd!==0">{{innerItem.zdtwf===''||innerItem.zdtwf===null?'-':innerItem.zdtwf}}</td>
                  <td>{{innerItem.pjtwf===''||innerItem.pjtwf===null?'-':innerItem.pjtwf}}</td>
                  <td>{{innerItem.zgtwf===''||innerItem.zgtwf===null?'-':innerItem.zgtwf}}</td>
                  <td>{{innerItem.wnjhs===''||innerItem.wnjhs===null?'-':innerItem.wnjhs}}</td>
                  <td rowspan="4" v-if="index===0">{{innerItem.jnjhs===''||innerItem.jnjhs===null?'-':innerItem.jnjhs}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else>
      <div
        class="school-info"
        v-for="(item, index) in schoolData.data.list"
        :key="index"
      >
        <div class="info-logo">
          <router-link
            tag="a"
            target="_blank"
            :to="{
              path: '/schoolDetail' + jQueryroute.path,
              query: { item: item.gb }
            }"
          >
            <img :src="item.logo" alt="院校logo" width="100%" height="100%" />
          </router-link>
        </div>
        <div class="info-introduce">
          <div class="school-name">
            <router-link
              tag="a"
              target="_blank"
              :to="{
                path: '/schoolDetail' + jQueryroute.path,
                query: { item: item.gb }
              }"
            >
              <h3>{{ item.yxmc }}</h3>
            </router-link>
            <div class="school-lqgl" v-if="jQueryroute.path === '/schoolRecommend'">
              (录取率:{{ item.lqgl }}%)
            </div>
          </div>
          <div class="school-category">
            <div class="category-wrap category-type">
              <div class="category-item top-positon">
                院校性质:
                <span v-if="item.sfgb !== '0'">公办&nbsp;</span>
                <span v-else-if="item.sfmb !== '0'">民办&nbsp;</span>
                <span v-else-if="item.sfdl !== '0'">独立学院&nbsp;</span>
                <span v-else>暂无信息</span>
              </div>
              <div class="category-item top-positon">
                院校属性:
                <span v-if="item.sf985 !== '0'">985&nbsp;</span>
                <span v-if="item.sf211 !== '0'">211&nbsp;</span>
                <span v-else>普通高校</span>
              </div>
              <div class="category-item top-positon">
                所在省份:{{ item.yxdq }}
              </div>
              <div class="category-item">
                <button
                  type="button"
                  class="deleteSchool"
                  v-if="item.collect"
                >
                  取消收藏
                </button>
                <button
                  type="button"
                  class="addSchool"
                  v-else
                >
                  加入收藏
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

      <div class="compareLayout" id="compare_box" style="bottom:-300px;">
      <div class="hd">
        <ul class="tab">
          <li class="current">院校对比</li>
        </ul>
        <div class="close"><a href="javascript:;" @click="hideYxdb">隐藏</a></div>
      </div>
      <div class="bd">
        <ul class="list" style="display:block;">
          <!-- 对比选项容器 -->
          <div id="compare_item_box">
            <li class="compare_item" style="float: left;width: 16.5%;text-align: center;"
            v-for="(item,index) in compareSchool" :key="index">
              <div class="box" style="border-right: 1px dashed #D4D4D4;height: 140px;padding-top: 8px;">
                <div class="schoolLogo" style="width: 120px;height: 100px;margin: 0 auto 5px;">
                  <a href="#">
                    <img width="80" height="80" :alt="item.yxmc" :src="item.logo">
                  </a>
                </div>
                <div class="tit" style="display: flex; flex-direction:column">{{item.yxmc}}</div>
              </div>
            </li>
          </div>
          <!-- 对比按钮 -->
          <li id="compare_tool">
            <div class="box">
              <div class="button" id="compare_button">
                <a href="javascript:;" @click="zyFsdb" class="btn">专业录取分数对比</a>
                <a href="javascript:;" @click="yxDbTable" class="btn">院校基本情况对比</a>
              </div>
              <a href="javascript:;" @click="clearYxdb">清空对比栏</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import jQuery from 'jquery';
import {Message} from 'element-ui';
import router from '../router/index';
export default {
data(){
    return{
      jQuery:jQuery,
      isShowCompare:false,
      currentArr:[]
    };
},
methods:{
  // 封装的院校对比代码
  yxdbCode(){
      // var compare;  // 全局变量，页面调用，给此赋值new Compare()即可
  (function(jQuery){
      jQuery.fn.compare = function(option) {
          var defaults = {
            maxItem : 4,// 最大角标
            compareUrl : '/db/jbxx',// 对比基本情况地址
            compareUrlFs : '/db/lqfs',// 对比录取分数地址
            compareUrlZYFs : '/db/zyfs',// 对比专业地址
            zydm:"#zydms", // 传递专业代码
            itemBox : '#compare_item_box',// 存放对比项的id
            itemClass : '.compare_item',// 每个对比项的类
            buttonBox : '#compare_button', // 存放对比按钮的类
            okButton : 'btn',// ok时的对比按钮class
            errorButton : 'btnDisabled',// error时的对比按钮class
            urlPath : 'xx/xx/xx?yxxh=', // 院校详细查询的url
            runInit : true,// 是否进行初始化
            iszydb : false // 是否专业对比
          };
          // 配置项
          var opt = jQuery.extend({}, defaults, option);
          var comp = new Compare(jQuery(this), opt);
          return comp;
      };
      /**
      * 构造方法
      */
      function Compare(obj, setting) {
          this._obj = obj;
          this.maxItem = setting.maxItem;
          this.compareUrl = setting.compareUrl;
          this.compareUrlFs = setting.compareUrlFs;
          this.compareUrlZYFs = setting.compareUrlZYFs;
          this.itemBox = setting.itemBox;
          this.itemClass = setting.itemClass;
          this.buttonBox = setting.buttonBox;
          this.okButton = setting.okButton;
          this.errorButton = setting.errorButton;
          this.runInit = setting.runInit;
          this.urlPath = setting.urlPath;
          this.zydm=setting.zydm;
          this.iszydb = setting.iszydb;
          // 存放对比数据
          // this._data = new Array();
          this._data = [];
          if(this.runInit === true){
            this.init();
          }
      }
      /**
      * 初始化操作
      */
      Compare.prototype.init = function() {
          this.before();
          return this;
      };
      /**
      * 动作之前的判断行为
      */
      Compare.prototype.before = function() {
          var leng = this._data.length;
          if(leng<2){
            this._obj.find(this.buttonBox+" a").removeClass("btn");
            this._obj.find(this.buttonBox+" a").addClass("btnDisabled");
          }else{
            this._obj.find(this.buttonBox+" a").removeClass("btnDisabled");
            this._obj.find(this.buttonBox+" a").addClass("btn");
          }
          if(this.iszydb === false){
            this._obj.find(this.buttonBox+" a:first").hide(); // 如果不是专业对比，第一个不显示
          }
      };
      /**
        * 将信息添加到对比框
        * @param obj.sid  大学id
        * @param obj.title 大学标题
        * @param obj.logo  大学校徽
        * @param obj.comp_pc  大学批次
        */
      Compare.prototype.add = function(obj) {
          var isAppend = true; // 标识 判断是否添进新元素
          // var isAddClass = true; // 判断是否点击后添加CLASS样式
          var isMsg = '您已经添加过该大学了'; // 弹出友情提示的信息
          var isChoose = 1;
          // var item = new Object();
          var item = {};
          var $obj = jQuery(obj);
          item.id = $obj.attr("comp_id");
          item.title = $obj.attr("comp_yxmc");
          item.logo = $obj.attr("comp_logo");
          item.pc = $obj.attr("comp_pc");

          // 如果数组中有元素了 则开始判断
          if(this._data.length > 0) {
            // 插入之前先判断当前数组中是否含有此元素 通过唯一标识 大学id
            jQuery.each(this._data, function(k, v){
              if(v.id === item.id) {
                isAppend = false;
                return false;
              }
            });
            // alert(this.maxItem);
            // 如果添加到元素超过了最大限制 也不再添加了
            if(isAppend && this._data.length >= this.maxItem+1) {
              isAppend = false;
              isMsg = '最多不允许超过'+(this.maxItem+1)+'所大学';
              // layer.alert(isMsg);
              Message({message:isMsg});
            }
            // 如果已经选择了该大学，则进行反选操作
            if(!isAppend && isChoose === 1) {
              this.close(item.id);
              return false;
            }
          }
          // 插入新元素
          if(isAppend) {
            this._data.push(item);
            var appendHtml = '';
            // var url = this.urlPath;
            appendHtml = '<li class="compare_item del_id_'+item.id+'" style="float: left;width: 16.5%;text-align: center;"><div class="box" style="border-right: 1px dashed #D4D4D4;height: 140px;padding-top: 8px;"><div class="schoolLogo" style="width: 120px;height: 100px;margin: 0 auto 5px;"><a href="#"><img src="'+item.logo+'" width="80" height="80" alt="'+item.title+'" /></a></div><div class="tit" style="display: flex; flex-direction:column">'+item.title+'<br /></div></div></div></li>';
            this._obj.find(this.itemBox).append(appendHtml);
            // 显示对比框
            this.show();
            // 添加样式
            jQuery(obj).addClass('compare_btn_'+item.id+' current');
            jQuery(obj).text("取消院校对比");
          }
          this.show();
          return false;
      };
      Compare.prototype.setTxt=function(obj){
            const dateSeconds=new Date().getSeconds();
            const sjs=Math.floor(Math.random()*100);
            const str=dateSeconds+""+sjs;
            jQuery(obj).addClass('compare_btn_'+str+' current');
            jQuery(obj).text("取消院校对比");
      };
      Compare.prototype.setCompareTXt=function(obj){
                    const dateSeconds=new Date().getSeconds();
            const sjs=Math.floor(Math.random()*100);
            const str=dateSeconds+""+sjs;
            jQuery(obj).removeClass('compare_btn_'+str+' current');
            jQuery(obj).text("加入院校对比");
      };
      /**
        * window.open到分数对比
        */
      Compare.prototype.compareDoFs = function (){
        var temp = this._data;
        var yxxhs = "";
        var pc = temp[0].pc;
        jQuery.each(temp,function(i,v){
          yxxhs += v.id+",";
        });
        yxxhs = yxxhs.substring(0,yxxhs.length-1);
        if(typeof (WeixinJSBridge)!=="undefined"){
          var i = sessionStorage.getItem("id");
          if(i == null||i===""){i=0;};
          i++;
          sessionStorage.setItem("id", i);
          sessionStorage.setItem("pre_href"+"_"+i, window.location.href);
        }
        window.open(this.compareUrlFs+"?yxxhs="+yxxhs+"&pc="+pc,"分数对比");
        return false;
      };
      /**
        * window.open到基本信息对比
        */
      Compare.prototype.compareDo = function (){
        var temp = this._data;
        var yxxhs = "";
        var pc = temp[0].pc;
        jQuery.each(temp,function(i,v){
          yxxhs += v.id+",";
        });
        yxxhs = yxxhs.substring(0,yxxhs.length-1);
        if(typeof (WeixinJSBridge)!=="undefined"){
          var i = sessionStorage.getItem("id");
          if(i == null||i===""){i=0;};
          i++;
          sessionStorage.setItem("id", i);
          sessionStorage.setItem("pre_href"+"_"+i, window.location.href);
        }
        console.log(yxxhs,pc);
        const url= router.resolve({
          path:"/db/jbxx",
          query:{yxxhs:yxxhs,pc:pc}
        });
        window.open(url.href,"_blank");
        // window.open(this.compareUrl+"?yxxhs="+yxxhs+"&pc="+pc,"基本信息对比");
        return false;
      };
      /**
        * window.open到专业对比
        */
      Compare.prototype.compareZy = function (){
        var temp = this._data;
        var yxxhs = "";
        jQuery.each(temp,function(i,v){
          yxxhs += v.id+",";
        });
        yxxhs = yxxhs.substring(0,yxxhs.length-1);
        var zydm = jQuery("#zydms").val();
        if(typeof (WeixinJSBridge)!=="undefined"){
          var i = sessionStorage.getItem("id");
          if(i == null||i===""){i=0;};
          i++;
          sessionStorage.setItem("id", i);
          sessionStorage.setItem("pre_href"+"_"+i, window.location.href);
        }
        window.open(this.compareUrlZYFs+"?yxxhs="+yxxhs+"&zydm="+zydm,"专业分数对比");
        return false;
      };
      /**
        * 清空
        */
      Compare.prototype.clear = function() {
        this._obj.find(this.itemClass).remove();
        if(this._data.length > 0) {
          // 获取对比框中的数组
          jQuery.each(this._data, function(k, v){
            jQuery(document).find('.compare_btn_'+v.id).removeClass('current'); // 清除页面已经选择的
            jQuery(document).find('.compare_btn_'+v.id).text("加入院校对比");
          });
        }
        // this._data = new Array();
        this._data = [];
        this.before();
        return this;
      };
      /**
      * 显示对比框 不包含数据 ;
      */
      Compare.prototype.show = function(){
          this.before();
          this._obj.animate({
            bottom:0
          }, 500);
          this.cstyle();
          return this;
      };
      /**
        * 删除掉指定的对比元素
        */
      Compare.prototype.close = function(sid) {
        // var tmp = new Array();
        var tmp = [];
        // 重新创建数组，不包括 被删除掉的大学
        jQuery.each(this._data, function(k, v){
          if(sid !== v.id) {
            tmp.push(v);
          }
        });
        // 删除标签元素
        this._obj.find('.del_id_'+sid).remove();
        jQuery(document).find('.compare_btn_'+sid).removeClass('current');
        jQuery(document).find('.compare_btn_'+sid).text("加入院校对比");
        this._data = tmp;
        this.before();
        return this;
      };
      /**
      * 隐藏
      */
      Compare.prototype.hide = function(){
          this.before();
          this._obj.animate({
            bottom:'-300px'
          }, 500);
          this.restyle();
          return this;
      };
      /**
       * 检查一个加入对比的元素是否是在对比数组中,并操作设置成加入对比
       **/
      Compare.prototype.fullOne = function(obj){
        if(this._data.length > 0) {
          jQuery.each(this._data, function(index, v){
            if(jQuery(obj).attr("comp_id") === v.id) {
              jQuery(obj).addClass('compare_btn_'+v.id+' current');
              jQuery(obj).text("取消对比");
              return false;
            }
          });
        }
      };
      /**
       * 需要修改页面的样式
       */
      Compare.prototype.cstyle = function() {
        if(this.iszydb){
          jQuery("#majorContent").css('marginBottom', '110px');
        }else{
          jQuery('#loadcontent').css('marginBottom', '110px');
        }
      };

      /**
       * 修复原来的样式
       */
      Compare.prototype.restyle = function() {
        if(this.iszydb){
          jQuery("#majorContent").css('marginBottom', '20px');
        }else{
          jQuery('#loadcontent').css('marginBottom', '10px');
        }
      };
  })(jQuery);
  },
  // 初始化院校对比
  initYxdb(){
    jQuery.compare = jQuery("#compare_box").compare({
          iszydb: false
        });
  },
  // 点击加入院校对比
  yxdbClick(e,item,index,flag){
    // jQuery.compare.add(e.target);
    jQuery.compare.show();
    // this.isShowCompare=true;
    if(flag===1){
      const arr=[];
      const compares=this.compareSchool;
      if(compares.length>4){
        const msg="最多不允许超过"+(compares.length+"所学校");
        this.$message({
          message:msg
        });
        return;
      }
      const currentArr=[...this.currentArr];
      currentArr.push(index);
      this.currentArr=currentArr;
      arr.push({pc:item.pc,yxxh:item.yxxh,yxmc:item.yxmc,logo:'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'});
      this.$store.dispatch("saveCompareSchool",{compareSchool:[...this.compareSchool,...arr]});
    }else{
      let dqSchoolIndex=-1;
      const schoolArr=[...this.compareSchool];
      for(let i=0;i<schoolArr.length;i++){
        if(schoolArr[i].yxxh===item.yxxh){
          dqSchoolIndex=i;
          break;
        }
      }
      if(dqSchoolIndex>=0){
        schoolArr[dqSchoolIndex]=null;
        const saveSchoolArr=schoolArr.filter(item=>item!==null);
        this.$store.dispatch("saveCompareSchool",{compareSchool:saveSchoolArr});
      }
      const currentArr=[...this.currentArr];
      let currentIndex=-1;
      for(let i=0;i<currentArr.length;i++){
        if(currentArr[i]===index){
          currentIndex=i;
          break;
        }
      }
      if(currentIndex>=0){
        currentArr[currentIndex]=null;
        this.currentArr=currentArr.filter(item=>item!==null);
      }
    }
    return false;
  },
  // 清空对比表
  clearYxdb(){
  //  jQuery.compare.clear();
      this.$store.dispatch("initCompareSchool");
      this.currentArr=[];
  },
  // 点击专业录取分数对比
  zyFsdb(){
    jQuery.compare.compareZy();
  },
  // 点击院校基本情况对比
  yxDbTable(){
    // jQuery.compare.compareDo();
    const arr=this.compareSchool;
    let yxxhs='';
    for(let i=0;i<arr.length;i++){
      const yxxh=arr[i].yxxh;
      if(yxxh){
        if(i===arr.length-1){
          yxxhs+=yxxh;
        }else{
          yxxhs+=yxxh+",";
        }
      }
    }
    const pc=arr[0].pc;
    const url= router.resolve({
        path:"/db/jbxx",
        query:{yxxhs:yxxhs,pc:pc}
    });
    window.open(url.href,"_blank");
  },
  // 隐藏院校对比
  hideYxdb(){
    jQuery.compare.hide();
    // this.isShowCompare=false;
  },
  // 取消院校对比
  cancelYxdb(id){
    jQuery.compare.close(id);
  }
},
mounted(){
  this.yxdbCode();
  this.initYxdb();
  const schoolList=[...this.schoolFindData.data.list];
  const compares=this.compareSchool;
  for(let i=0;i<schoolList.length;i++){
    schoolList[i].isShowCompare=false;
  }
  const arr=[];
  for(let i=0;i<schoolList.length;i++){
    for(let j=0;j<compares.length;j++){
      if(compares[j].yxmc===schoolList[i].yxmc){
        schoolList[i].isShowCompare=true;
        arr.push(i);
      }
    }
  }
  this.currentArr=[...arr];
  this.$store.commit("getSchoolFind",{schoolFindData:{data:{list:schoolList,total:this.schoolFindData.data.total,pageSize:this.schoolFindData.data.pageSize,pageNum:this.schoolFindData.data.pageNum}}});
},
watch:{
  $route(to,from){
    if(
      (to.path === "/schoolRecommend" || to.path === "/schoolfinder") &&
        (from.path === "/schoolRecommend" || from.path === "/schoolfinder"||
        from.path==="/majorquery"||from.path==="/zypg"||from.path==="/datahome"||
        from.path==="/me/updateScore"||from.path==="/me/updatePwd"||from.path==="/buyindex")
        ){
          this.$store.commit("initSchoolFind");
        this.$store.commit("initCompareSchool");
          // this.yxdbCode();
          // this.initYxdb();
        }
  }
},
computed:{
    ...mapState(["schoolFindData","userInfo","compareSchool"])
}
};
</script>
<style lang="less" scoped>
.school-data-wrap{
    width: 1100px;
    margin: 0 auto;
    padding: 0 30px 15px;
    box-sizing: border-box;
    background-color: #fff;
    .school-info{
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #e4e7ed;
      .info-logo{
        width: 100px;
        height: 100px;
      }
      .info-introduce{
        width: 940px;
        padding-left: 20px;
        box-sizing: border-box;
        .school-name{
          display: flex;
          align-items: center;
          font-size: 20px;
          color: #303133;
        }
        .school-category{
          width: 920px;
          display: flex;
          font-size: 14px;
          .category-wrap{
            width: 35%;
            color: #666;
            .top-position{
              margin-top: 20px;
            }
            .category-item{
              display: inline-block;
              width: 150px;
              margin-bottom: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .deleteSchool{
              width: 100px;
              height: 30px;
              font-size: 14px;
              border: 1px solid #2186de;
              border-radius: 5px;
              color: #2186de;
              background-color: #fff;
              outline: none;
              cursor: pointer;
            }
            .addSchool{
              width: 100px;
              height: 30px;
              font-size: 14px;
              border: 1px solid #f90;
              border-radius: 5px;
              color: #f90;
              background-color: #fff;
              outline: none;
              cursor: pointer;
            }
          }
          .info-data-wrap{
            width: 65%;
            .info-data{
              width: 100%;
              color: #666;
              th{
                border: 1px solid #09f;
                padding: 1px 0;
                text-align: center;
                background-color: #e4f5ff;
              }
              td{
                padding: 1px 5px;
                border: 1px solid #09f;
                text-align: center;
              }
            }
          }
        }
      }
    }
}
.compareLayout {
    height: 220px;
    width: 984px;
    position: fixed;
    bottom: 0;
    left: 50%;
    margin-left: -492px;
    z-index: 9999;
    .hd{
      padding-top: 3px;
    }
    .tab{
      border: 1px solid #9F9999;
      border-left: 0;
      border-bottom: 0;
      height: 32px;
      padding-left: 1px;
      background-color: #FFF;
      li.current{
        color: #e86f1e;
        font-weight: bold;
        border: 2px solid #006bcf;
        border-bottom: 0;
        margin-top: -3px;
        position: relative;
        background-color: #FFF;
        line-height: 35px;
      }
      li{
        float: left;
        padding: 0 30px;
        line-height: 32px;
        cursor: pointer;
        font-size: 15px;
        color: #555;
        border-right: 1px solid #9F9999;
        border-left: 1px solid #9F9999;
        margin-left: -1px;
      }
    }
    a{
      color: #005AA0;
    }
    .bd{
      border: 2px solid #006bcf;
      height: 180px;
      background-color: #FFF;
    }
    .list{
      padding: 10px 13px;
      height: 160px;
      display: none;
      li{
        float: left;
        width: 16.5%;
        text-align: center;
      }
      .box{
        border-right: 1px dashed #D4D4D4;
        height: 140px;
        padding-top: 8px;
      }
      #compare_tool
      {
        .box {
        padding-top: 0px;
        }
      }
      .btnDisabled {
        width: 130px;
        height: 32px;
        line-height: 32px;
        margin: 8px auto 5px;
        display: block;
        border: 1px solid #E5E5E5;
        color: #999;
        border-radius: 5px;
        cursor: default;
    }
    .btn{
      width: 130px;
      height: 34px;
      line-height: 34px;
      margin: 8px auto 10px;
      display: block;
      background-color: #2186de;
      color: #FFF;
    }
    }
    .close{
      position: absolute;
      right: 10px;
      top: 10px;
    }
}
 .btnBt {
    display: inline-block;
    margin-top: 15px;
    font-size: 16px;
    color: #606266;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
}
 .btnBt.current {
    color: #fff;
    background-color: #2186de;
}
.yuceTdx{
    color: #ED4A09;
}
</style>
