<template>
    <div class="conditions-container">
        <div class="condition-panel">
            <div class="condition-item">
                <label class="item-title">院校名称</label>
                <div class="area-item last-item">
                    <input v-model="param.yxmc" type="text" placeholder="限定院校名称中包含的关键字" class="item-text"/>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">所在省份</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in yxdq"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('yxdq',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">热门城市</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in hotCity"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('hotCity',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">院校类型</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in yxType"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('yxType',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">办学性质</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in bxType"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('bxType',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">录取批次</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in pcType"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('pcType',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">院校特色</label>
                <div class="area-item">
                    <span class="item-name" v-for="(item,index) in yxts"
                    :key="index" :aa="item.value" :class="{ 'current-item': item.isActive }"
                    @click="checkItem('yxts',item,index)">{{item.name}}</span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">数据筛选</label>
                <div class="area-item" style="box-sizing: border-box; padding-left: 70px;">
                    <span class="item-name">
                        <el-radio-group v-model="sjsx" @input="changeSjSx">
                            <el-radio :label="0">无</el-radio>
                            <el-radio :label="1">最低等效分区间</el-radio>
                            <el-radio :label="2">录取线差区间</el-radio>
                            <el-radio :label="3">新增院校</el-radio>
                        </el-radio-group>
                    </span>
                </div>
            </div>
            <div class="condition-item">
                <label class="item-title">           </label>
                <div class="area-item" style="margin-left:220px;height:50px;">
                    <span class="item-name" v-if="sjsx===1">
                        <input v-model="minTwf" class="term-text"
                        @change="changeMinTwf(minTwf)"/>——<input v-model="maxTwf" class="term-text"
                        @change="changeMaxTwf(maxTwf)"/>
                    </span>
                    <span class="item-name" v-if="sjsx===2">
                        <input v-model="minLqxc" class="term-text"
                        @change="changeMinLqxc(minLqxc)"/>——<input v-model="maxLqxc" class="term-text"
                        @change="changeMaxLqxc(maxLqxc)"/>
                    </span>
                </div>
            </div>
        </div>
        <div class="condition-btn">
            <el-button type="primary" @click="querySchool" @keyup.enter="keyUpEnter"
            style="width:150px;font-size: 16px;">开始查询</el-button>
            <el-button type="primary" @click="resetSelect"
            style="width:150px;font-size: 16px;">重置条件</el-button>
        </div>
        <div
      style="max-width:1100px;margin:20px auto;min-height: 100px;"
      element-loading-text="正在加载中"
      id="loadingDiv"
    >
    <div
      class="school-count"
      v-if="schoolFindData.data && schoolFindData.data.list.length > 0&&schoolFlag"
    >
      每页{{ schoolFindData.data.pageSize }}条，共找到{{
        schoolFindData.data.total
      }}条记录
    </div>
    <Schoolfind
        v-if="schoolFindData.data && schoolFindData.data.list.length > 0&&schoolFlag"/>
      <div
        class="remarks"
        v-if="schoolFindData.data && schoolFindData.data.list.length === 0&&schoolFlag"
      >
        暂无符合条件的院校
      </div>
      <div
        class="pagination"
        v-if="schoolFindData.data && schoolFindData.data.list.length > 0&&schoolFlag"
      >
        <el-pagination
        background
      @size-change="handleSizeChange"
      @current-change="handleClickPage"
      :current-page="schoolFindData.data.pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="schoolFindData.data.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="schoolFindData.data.total">
    </el-pagination>
      </div>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
import Schoolfind from './SchoolFindData.vue';
export default {
props:["conditionsExt","requestParam"],
data(){
    return{
        yxdq:this.conditionsExt.yxdq,
        hotCity:this.conditionsExt.hotCity,
        yxType:this.conditionsExt.yxType,
        bxType:this.conditionsExt.bxType,
        pcType:this.conditionsExt.pcType,
        yxts:this.conditionsExt.yxts,
        sjsx:0,
        // mintwf=fs-50 maxtwf=fs+20
        minTwf:"480",
        maxTwf:"550",
        minLqxc:"0",
        maxLqxc:"180",
        loading:false,
        schoolFlag:true,
        param:{}
    };
},
methods:{
    // 改变搜索条件
    checkItem(itemTxt,item,index){
        switch (itemTxt) {
            case 'yxdq':
                this.changeCheckItem(item,index,"yxsd",this.yxdq);
                break;
            case 'hotCity':
                this.changeCheckItem(item,index,"rmcs",this.hotCity);
                break;
            case 'yxType':
                this.changeCheckItem(item,index,"xxlb",this.yxType);
                break;
            case 'bxType':
                if(index===0){
                    this._innerMap(this.bxType);
                    this.param.bxlb=item.value+"";
                }else{
                    this.bxType.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.bxlb=item.value+"";
                }
                break;
            case 'pcType':
                if(index===0){
                    this._innerMap(this.pcType);
                    this.param.lqpc="0";
                    this.param.zxlx="";
                }else{
                    this.pcType.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.lqpc=item.value;
                    this.param.zxlx=item.xgzg;
                }
                break;
            case 'yxts':
                if(index===0){
                    this._innerMap(this.yxts);
                    this.param.sf985="0";
                    this.param.sf211="0";
                    this.param.sfsyl="0";
                    this.param.sf101="0";
                }else if(index===1){
                    this.yxts.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.sf985="0";
                    this.param.sf211="1";
                    this.param.sfsyl="0";
                    this.param.sf101="0";
                }else if(index===2){
                    this.yxts.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.sf985="1";
                    this.param.sf211="0";
                    this.param.sfsyl="0";
                    this.param.sf101="0";
                }else if(index===3){
                    this.yxts.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.sf985="0";
                    this.param.sf211="0";
                    this.param.sfsyl="1";
                    this.param.sf101="0";
                }else{
                    this.yxts.map((innerItem,index2)=>{
                        if(index2===0||index2!==index){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    this.param.sf985="0";
                    this.param.sf211="0";
                    this.param.sfsyl="0";
                    this.param.sf101="1";
                }
                break;
            default:
                break;
        }
    },
    // 初始化条件
    _innerMap(data){
        data.map((item,index)=>{
            if(index===0){
                item.isActive=true;
            }else{
                item.isActive=false;
            }
        });
    },
    changeCheckItem(item,index,param,handleData){
        if(index===0){
                    this._innerMap(handleData);
                    this.param[param]="";
                }else if(index!==0&&item.isActive){
                    this.$set(item,"isActive",false);
                    let yxArr=[];
                    if(this.param[param].indexOf(",")){
                        yxArr=this.param[param].split(",");
                    }
                    yxArr=yxArr.filter((innerItem)=>{
                        return innerItem!==item.value;
                    });
                    if(yxArr.length===0){
                        this._innerMap(handleData);
                        this.param[param]="";
                    }else{
                        this.param[param]=yxArr.join(",");
                    }
                }else{
                    handleData.map((innerItem,index)=>{
                        if(index===0){
                            innerItem.isActive=false;
                        }
                    });
                    this.$set(item,"isActive",true);
                    let yxArr=[];
                    if(this.param[param].indexOf(",")>=0||this.param[param].length>0){
                        yxArr=this.param[param].split(",");
                        yxArr.push(item.value);
                        this.param[param]=yxArr.join(",");
                    }else{
                        yxArr.push(item.value);
                        this.param[param]=yxArr.join(",");
                    }
                }
    },
    // 重置条件
    resetSelect(){
        this._innerMap(this.yxdq);
        this._innerMap(this.hotCity);
        this._innerMap(this.yxType);
        this._innerMap(this.bxType);
        this._innerMap(this.pcType);
        this._innerMap(this.yxts);
        this.param={
            yxmc:"",
            yxsd:"",
            rmcs:"",
            xxlb:"",
            bxlb:"",
            lqpc:"0",
            zxkm:"0",
            sf985:"0",
            sf211:"0",
            sfsyl:"0",
            sf101:"0",
            minzdtwf:"",
            maxzdtwf:"",
            minlqxc:"",
            maxlqxc:"",
            xzyx:"0",
            zxlx:"",
            gender:"",
            sfzhl:"",
            current:"1",
            size:"10",
            kl:this.userInfo.kl,
            zxkma:this.userInfo.zxkma,
            zxkmb:this.userInfo.zxkmb
        };
        this.sjsx=0;
    },
    // 改变数据筛选
    changeSjSx(selectedValue){
        if(selectedValue===0){
            this.param.xzyx="0";
            this.param.minzdtwf="";
            this.param.maxzdtwf="";
            this.param.minlqxc="";
            this.param.maxlqxc="";
            this.param.kl=this.userInfo.sxkm;
        }else if(selectedValue===1){
            this.param.xzyx="0";
            this.param.minzdtwf=this.minTwf;
            this.param.maxzdtwf=this.maxTwf;
            this.param.minlqxc="";
            this.param.maxlqxc="";
            this.param.kl=this.userInfo.sxkm;
        }else if(selectedValue===2){
            this.param.xzyx="0";
            this.param.minzdtwf="";
            this.param.maxzdtwf="";
            this.param.minlqxc=this.minLqxc;
            this.param.maxlqxc=this.maxLqxc;
            this.param.kl=this.userInfo.sxkm;
        }else if(selectedValue===3){
            this.param.xzyx="1";
            this.param.minzdtwf="";
            this.param.maxzdtwf="";
            this.param.minlqxc="";
            this.param.maxlqxc="";
            this.param.kl=this.userInfo.sxkm;
        }
    },
    // 院校查询
    async querySchool(){
        this.schoolFlag=false;
        const loading=this.$loading({fullscreen: true,target:document.getElementById("loadingDiv"),text:"正在加载中"});
        this.param.pageNum=1;
        this.param.pageSize=10;
        await this.$store.dispatch("getSchoolFind",{requestParam:this.param});
        loading.close();
        this.schoolFlag=true;
    },
    // 改变page
   async handleClickPage(pageNum){
    this.schoolFlag=false;
    const loading=this.$loading({fullscreen: true,target:document.getElementById("loadingDiv"),text:"正在加载中"});
      this.param.pageNum = pageNum;
     await this.$store.dispatch("getSchoolFind", {
        requestParam:this.param
      });
      loading.close();
      this.schoolFlag=true;
    },
    // 改变size
    async handleSizeChange(pageSize){
        this.schoolFlag=false;
        const loading=this.$loading({
            fullscreen:true,
            target:document.getElementById("loadingDiv"),
            text:"正在加载中"
        });
        this.param.pageNum=1;
        this.param.pageSize=pageSize;
        await this.$store.dispatch("getSchoolFind", {
        requestParam:this.param
      });
      loading.close();
      this.schoolFlag=true;
    },
    // 改变最小等效分
    changeMinTwf(minTwf){
        this.param.minzdtwf=minTwf;
    },
    // 改变最大等效分
    changeMaxTwf(maxTwf){
        this.param.maxzdtwf=maxTwf;
    },
    changeMinLqxc(minLqxc){
        this.param.minlqxc=minLqxc;
    },
    changeMaxLqxc(maxLqxc){
        this.param.maxlqxc=maxLqxc;
    },
    // 按下回车键触发查询
    async keyUpEnter(e){
        if(e.key==='Enter'){
            await this.querySchool();
        }
    }
},
computed:{
    ...mapState(["schoolFindData","userInfo"])
},
mounted(){
    this.minTwf=this.userInfo.gkfs-50+"";
    this.maxTwf=this.userInfo.gkfs+20+"";
    this.param=this.requestParam;
    // window.addEventListener('keydown',this.keyUpEnter);
    this.resetSelect();
},
watch:{
  $route(to,from){
    if(
      (to.path === "/schoolRecommend" || to.path === "/schoolfinder") &&
        (from.path === "/schoolRecommend" || from.path === "/schoolfinder"||
        from.path==="/majorquery"||from.path==="/zypg"||from.path==="/datahome"||
        from.path==="/me/updateScore"||from.path==="/me/updatePwd"||from.path==="/buyindex")
        ){
          this.$store.commit("initSchoolFind");
          this.$store.commit("initCompareSchool");
        //   this.yxdbCode();
        //   this.initYxdb();
        }
  }
},
components:{
Schoolfind
}
};
</script>
<style lang="less" scoped>
.conditions-container{
    .condition-panel{
    border: 1px solid #dcdfe6;
    width: 1100px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    background-color: #fff;
    .condition-item{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;
        .item-title{
            display: inline-block;
            width: 100px;
            height: 40px;
            line-height: 30px;
            text-align: right;
            margin: 0 5px;
            font-size: 14px;
            font-weight: 600;
            color: #606266;
        }
        .item-text{
            box-sizing: border-box;
            width: 500px;
            height: 30px;
            padding-left: 10px;
            outline: none;
            border: 1px solid #dcdfe6;
            transition: all .5s;
            color: #606266;
        }
        .term-text{
            box-sizing: border-box;
            width: 40px;
            height: 30px;
            padding-left: 10px;
            outline: none;
            // border: 1px solid #dcdfe6;
            border:none;
            transition: all .5s;
            color: #606266;
        }
        .area-item{
            width: 1000px;
            box-sizing: border-box;
            margin-left: 10px;
            padding-bottom: 8px;
            font-size: 13px;
            color: #2c3e50;
            border-bottom: 1px dashed #e4e7ed;
            .item-name{
                display: inline-block;
                padding: 3px 10px;
                margin: 2px 5px;
                border-radius: 3px;
                cursor: pointer;
            }
            .current-item{
                background-color: #409eff;
                color: #fff;
            }
        }
        .last-item{
            border: none;
            padding-bottom: 0;
        }
    }
}
    .condition-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0;
    }
    .pagination{
    width: 1100px;
    margin: 0 auto;
    padding: 10px 0 30px;
    text-align: center;
    background-color: #fff;
    }
}
.school-count{
    box-sizing: border-box;
    width: 1100px;
    margin: 0 auto;
    padding: 16px;
    color: #409eff;
}
.remarks {
    width: 1100px;
    margin: 0 auto;
    margin-top: 20px;
    color: #666;
    font-size:16px;
}

</style>
